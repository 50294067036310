.full-vh {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.area-login {
  width: 310px;
  color: #121212;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 1rem;
}
.s-text-s {
  font-size: x-small;
}
ion-content {
  --background: url("../img/logo-r.webp") #00242f;
}
ion-avatar {
  display: inline;
  height: 83px;
  width: 100%;
}
ion-avatar img {
  height: 83px;
}
